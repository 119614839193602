.list {
	display: grid;

	li {
		font-weight: 300;

		strong {
			font-weight: 600;
		}
	}
}
