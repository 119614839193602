@use '../abstracts' as *;

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

*::after,
*::before {
	display: inline-block;
}

a,
a:visited,
a:link {
	color: inherit;
	text-decoration: none;
}

li {
	color: inherit;
	list-style: none;
}

button {
	display: block;
	border: none;
	border-radius: 0;
	cursor: pointer;
}

input {
	color: var(--text-main);
	caret-color: var(--text-main);
}
