@use '../../sass/abstracts/' as *;

.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 4rem;

	@include breakpoint-up('large') {
		padding-top: 6rem;
	}

	@include breakpoint-up('xlarge') {
		padding-top: 8rem;
	}
}

.title {
	font-weight: bold;
	font-size: 6rem;
	margin-bottom: 3rem;

	@include breakpoint-up('large') {
		font-size: 8rem;
		margin-bottom: 4rem;
	}
}

.subtitle {
	font-size: 2.4rem;
	font-weight: 400;
	margin-bottom: 4rem;

	@include breakpoint-up('large') {
		font-size: 3rem;
		margin-bottom: 6rem;
	}
}

.homeButton {
	background-color: var(--bg-inverse);
	border-radius: var(--border-radius);
	box-shadow: var(--shadow-light);
	color: var(--text-inverse);

	padding: 4rem;
	font-size: 1.4rem;
	padding: 1rem 2rem;

	&:hover {
		background-color: var(--bg-inverse-hover);
	}

	@include breakpoint-up('large') {
		font-size: 1.6rem;
		padding: 1.5rem 4rem;
	}
}
