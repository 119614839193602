@use '../../sass/abstracts' as *;

.search {
	@include element;
	color: var(--text-secondary);
	width: 100%;
	height: 4.8rem;
	margin-bottom: 4rem;
	padding-left: 2.5rem;

	display: flex;
	align-items: center;

	@include breakpoint-up('large') {
		height: 5.6rem;
		margin-bottom: 0;
		max-width: 48rem;
	}

	input {
		padding-left: 1.5rem;
		border: none;
		background-color: transparent;
		height: 100%;
		width: 90%;

		&::placeholder {
			font-size: var(--font-size);
			color: currentColor;
		}

		&:focus {
			font-size: 16px;
			outline: none;

			@include breakpoint-up('large') {
				font-size: inherit;
			}
		}
	}
}
