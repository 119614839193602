@use '../../sass/abstracts' as *;

.notFoundMessage {
	grid-template-columns: 1 / -1;
	margin-top: 8rem;
	text-align: center;
	font-weight: 600;
	font-size: 2rem;

	@include breakpoint-up('medium') {
		font-size: 3rem;
		margin-top: 12rem;
	}
}

.wrap {
	margin-top: 2.5rem;
	padding: 0 2rem;

	@include breakpoint-up('medium') {
		padding: 0 4rem;
	}

	@include breakpoint-up('large') {
		margin-top: 4.8rem;
		padding: 0;
	}
}

.list {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	gap: 4rem;

	@include breakpoint-up('medium') {
		grid-template-columns: repeat(2, 1fr);
	}

	@include breakpoint-up('large') {
		grid-template-columns: repeat(3, 1fr);
		gap: 7rem;
	}

	@include breakpoint-up('xlarge') {
		grid-template-columns: repeat(4, 1fr);
	}
}
