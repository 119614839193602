@use '../abstracts' as *;

html {
	box-sizing: border-box;
	height: 100%;
	font-size: 62.5%; //rem = 10px
}

body {
	max-height: 100vh;
	height: 100%;
	background-color: var(--bg);
	overflow-y: scroll;
}

.container {
	@extend %container;
}

::selection {
	background-color: var(--selection-bg);
	color: var(--selection-text);
}
