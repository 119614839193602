@use '../../sass/abstracts' as *;

@use '../../sass/abstracts' as *;

.wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	padding-top: 6rem;

	@include breakpoint-up('medium') {
		padding-top: 10rem;
	}
}

.loader {
	display: inline-block;

	width: 3rem;
	height: 3rem;

	border: 0.3rem solid var(--text-main);
	border-bottom-color: transparent;
	border-radius: 50%;

	animation: rotation 1s linear infinite;

	@include breakpoint-up('medium') {
		width: 4rem;
		height: 4rem;
		border: 0.4rem solid var(--text-main);
		border-bottom-color: transparent;
		border-radius: 50%;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
